// Here you can add other styles
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .table td, .table th { 
    background-color: transparent !important; 
  }
  td:nth-child(odd){
    font-weight:bold;
  }
}

@media print {
  .page-break {
    display: block;
    // page-break-before: always;
    page-break-after: always;
  }
}

@page {
  size: 210mm 297mm;
  margin: 10px;
}